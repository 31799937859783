<template>
  <div class="online">
    <div class="container" :style="{ paddingTop: '4.4rem' }">
      <!-- 底图 -->
      <img
        class="fixed-top"
        v-if="!soninfo.count || !is_play || sectiontype != 1"
        :src="info.images"
      />
      <!-- 视频 sectiontype == 1 -->
      <video-player
        class="fixed-top vjs-custom-skin"
        v-if="soninfo.count && is_play && sectiontype == 1 && !videoVisible"
        ref="videoPlayer"
        :playsinline="true"
        :options="playerOptions"
        @play="onPlayerPlay($event)"
        @pause="onPlayerPause($event)"
        @ended="onPlayerEnded($event)"
        @timeupdate="onPlayerTimeupdate($event)"
      ></video-player>

      <div v-html="videoContent" v-if="videoVisible"></div>
      <!-- 图文 sectiontype == 2 -->

      <!-- 音频 sectiontype == 3 -->
      <aplayer
        v-if="sectiontype == 3 && reFresh"
        :music="videoUpload.music"
        :class="navBarFixed == true ? 'music-fixed' : ''"
        ref="player"
      ></aplayer>

      <!-- 外链 sectiontype == 4 -->

      <div class="title">
        <div>
          <h4>{{ info.bookname }}</h4>
          <h6>{{ info.teacher_name }}</h6>
        </div>
        <div class="poster" @click="poster" v-show="vipshare == 1">
          <i class="jym-icon-test15"></i>
          <br />海报
        </div>
      </div>
      <div class="text">
        <div class="gold-num">￥{{ info.price }}</div>
        <div>
          <span class="gold-num">{{ info.num }}</span
          >人已购
          <i class="jym-shuxian"></i>
          <em>{{ soninfo.count }}节</em>
        </div>
      </div>

      <mt-navbar v-model="selected" :class="navBarFixed ? 'navBarWrap' : ''">
        <mt-tab-item id="1">课程目录</mt-tab-item>
        <mt-tab-item id="2">
          <router-link to="/shop">官方商城</router-link>          
          </mt-tab-item>
      </mt-navbar>

      <div v-show="navBarFixed" style="height: 0.95rem"></div>

      <mt-tab-container v-model="selected" class="switch" v-show="isView">
        <mt-tab-container-item id="1">
          <div v-if="soninfo.count > 0">
            <div v-for="(item, i) in soninfo.data" :key="i">
              <div
                class="list"
                @click="
                  videoPlay(
                    item.id,
                    item.is_free,
                    item.test_time,
                    item.sectiontype,
                    item.playtime,
                    i
                  )
                "
              >
                <div class="left">
                  <i class="jym-bofang1" v-show="item.sectiontype == 1"></i>
                  <i class="jym-tuwen1" v-show="item.sectiontype == 2"></i>
                  <i class="jym-yinyue" v-show="item.sectiontype == 3"></i>
                  <i class="jym-bofang1" v-show="item.sectiontype == 4"></i>
                  <span class="ell" :class="{ 'gold-color': index == i }" v-html="item.title"></span>
                  <em v-if="item.is_free == 1 && item.test_time > 0">试听</em>
                </div>
                <div class="right" v-if="is_play && item.sectiontype == 1">
                  <span v-if="item.playtime >= 120" class="gold-color"
                    >已看{{ item.playtime_m }}分钟</span
                  >
                  <span v-else-if="item.playtime > 0" class="gold-color"
                    >已看{{ item.playtime }}秒</span
                  >
                  <span v-else>未学习</span>
                </div>
              </div>
            </div>
          </div>
          <div v-else class="emptyData" v-show="initLoading">
            <img src="@assets/images/emptyData.png" />
            <p>暂无课程</p>
          </div>
        </mt-tab-container-item>
        <mt-tab-container-item id="2">
          <p v-if="info.descript" class="desc" v-html="info.descript"></p>
          <div v-else class="emptyData" v-show="initLoading">
            <img src="@assets/images/emptyData.png" />
            <p>暂无简介</p>
          </div>
        </mt-tab-container-item>
      </mt-tab-container>
    </div>

    <!-- 图文 -->
    <mt-popup
      v-model="contentVisible"
      position="center"
      :style="{ height: popupHeight }"
    >
      <i @click="contentVisible = false" class="jym-close dialog-close"></i>
      <div
        class="content"
        v-html="content"
        :style="{ height: popupHeight }"
      ></div>
    </mt-popup>

    <!-- 客服 -->
    <mt-popup
      v-model="serviceVisible"
      position="center"
      :style="{ height: auto }"
    >
      <i @click="serviceVisible = false" class="jym-close dialog-close"></i>
      <service :pay_qrcode="pay_qrcode"></service>
    </mt-popup>

    <!-- 海报 -->
    <mt-popup
      v-model="posterVisible"
      position="center"
      :style="{ height: auto }"
    >
      <i @click="posterVisible = false" class="jym-close dialog-close"></i>
      <poster :posterImg="posterImg"></poster>
    </mt-popup>

    <!-- 发送密码 -->
    <mt-popup v-model="codeVisible" position="center" style="width: 6.5rem">
      <i @click="codeVisible = false" class="jym-close dialog-close"></i>
      <div class="verify">
        <div class="code">
          <i class="jym-anquan"></i>
          <input type="text" v-model="verifyCode" placeholder="请输入密码" />
        </div>
        <div class="submit" @click="submitCode">提交</div>
      </div>
    </mt-popup>

    <!-- 分享 -->
    <ShareInfo
      v-on:setShareInfoStatus="setShareInfoStatus"
      :shareInfoStatus="shareInfoStatus"
    ></ShareInfo>

    <!-- 底部支付 -->
    <Footer-Pay
      @pay="pay"
      @collect="collect"
      @service="service"
      @poster="poster"
      @passwordEvent="passwordEvent"
      :is_pass="info.is_pass"
      :is_play="is_play"
      :is_collect="is_collect"
      :vipshare="vipshare"
    ></Footer-Pay>
  </div>
</template>

<script>
import aplayer from "vue-aplayer";
import { MessageBox } from "mint-ui";
import { isWeixin } from "@utils";
import { wechatEvevt } from "@libs/wechat";
import FooterPay from "@components/FooterPay";
import Poster from "@views/Lesson/poster";
import Service from "@views/Lesson/service";
import { lessonDetail, lessonRecord, lessonBill } from "@api/public";
import { getCollectAdd, getCollectDel, getUserInfo } from "@api/user";
import ShareInfo from "@components/ShareInfo";
import { mapGetters } from "vuex";
const _isWechat = isWeixin();

export default {
  name: "offline-detail",
  components: {
    FooterPay,
    Poster,
    Service,
    aplayer,
    ShareInfo,
  },
  data() {
    return {
      id: "",
      sid: "",
      spread: "",
      index: "",
      selected: "1",
      is_play: "",
      is_free: "",
      is_collect: "",
      durations: "",
      testTime: "",
      playtime: "",
      popupHeight: "",
      sectiontype: "",
      pay_qrcode: "",
      vipshare: "",
      share: "",
      verifyCode: "",
      posterImg: [],
      firstParams: [],
      paused: true,
      reFresh: true,
      videoVisible: false,
      contentVisible: false,
      serviceVisible: false,
      posterVisible: false,
      isView: false,
      navBarFixed: false,
      shareInfoStatus: false,
      initLoading: false,
      codeVisible: false,
      videoContent: "",
      content: "",
      timer: "",
      info: {},
      soninfo: {},
      playerOptions: {
        // 视频播放配置
        playbackRates: [0.5, 1.0, 1.5, 2.0], //可选择的播放速度
        autoplay: false, //如果true,浏览器准备好时开始回放。
        muted: false, // 默认情况下将会消除任何音频。
        loop: false, // 视频一结束就重新开始。
        preload: "auto", // 建议浏览器在<video>加载元素后是否应该开始下载视频数据。auto浏览器选择最佳行为,立即开始加载视频（如果浏览器支持）
        language: "zh-CN",
        aspectRatio: "16:9", // 将播放器置于流畅模式，并在计算播放器的动态大小时使用该值。值应该代表一个比例 - 用冒号分隔的两个数字（例如"16:9"或"4:3"）
        fluid: true, // 当true时，Video.js player将拥有流体大小。换句话说，它将按比例缩放以适应其容器。
        sources: [
          {
            // type: "video/mp4",
            type: "application/x-mpegURL",
            src: "",
          },
        ],
        poster: "", //你的封面地址
        notSupportedMessage: "此视频暂无法播放，请稍后再试", //允许覆盖Video.js无法播放媒体源时显示的默认信息。
        controlBar: {
          timeDivider: true, //当前时间和持续时间的分隔符
          durationDisplay: true, //显示持续时间
          remainingTimeDisplay: false, //是否显示剩余时间功能
          fullscreenToggle: true, //全屏按钮
        },
      },
      videoUpload: {
        // 音频播放配置
        progress: false,
        progressPercent: 0,
        successPercent: 0,
        music: {
          title: "",
          author: " ",
          url: "",
          pic: "",
          lrc: "",
        },
      },
    };
  },
  computed: mapGetters(["isLogin", "userInfo"]),
  created() {
    console.log('ll')
    this.$dialog.loading.open();
    this.id = this.$route.params.id;
    this.popupHeight = window.innerHeight / 50 - 4 + "rem";
    // this.getList();
    console.log('ll')
  },
  mounted() {
    console.log('ll')
    // 视频定时器
    this.timer = setInterval(this.putLearningObj, 20000);
    // 事件监听滚动条
    window.addEventListener("scroll", this.watchScroll);
    
    window.location.href = "/testh5/pages/subcontract/onlineClass/index?id=" + this.id
  },
  methods: {
    watchScroll() {
      let scrollTop =
        window.pageYOffset ||
        document.documentElement.scrollTop ||
        document.body.scrollTop;
      //  当滚动超过 80 时，实现吸顶效果
      if (scrollTop > 80) {
        this.navBarFixed = true;
      } else {
        this.navBarFixed = false;
      }
    },

    stopVideo() {
      this.$refs.videoPlayer.player.pause();
      MessageBox.confirm(
        "试听时间已结束，继续学习请购买本课程！"
      ).then(() => {});
    },

    // 定时请求保存进度
    putLearningObj() {
      let that = this;
      if (!that.paused && parseInt(that.durations) > parseInt(that.playtime)) {
        let params = {
          id: that.id,
          sid: that.sid,
          playtime: that.durations,
        };

        lessonRecord(params).then();
      }
    },

    // 视频播放
    onPlayerPlay() {
      this.paused = false;
    },

    // 视频暂停
    onPlayerPause() {
      this.paused = true;
    },

    // 视频播放结束
    onPlayerEnded() {
      this.paused = false;
    },

    // 获取视频播放时间
    onPlayerTimeupdate(player) {
      this.durations = parseInt(player.cache_.currentTime);
      // 试听时间结束视频禁止播放
      if (!this.is_play && this.is_free == 1 && this.test_time > 0) {
        if (this.testTime >= this.durations) {
          this.stopVideo();
        }
      }
    },

    // 列表点击事件
    videoPlay(sid, is_free, testTime, sectiontype, playtime, index) {
      let that = this;
      that.$set(that, "sid", sid);
      that.$set(that, "is_free", is_free);
      that.$set(that, "testTime", testTime);
      that.$set(that, "sectiontype", sectiontype);
      that.$set(that, "playtime", playtime);
      that.$set(that, "index", index || 0);

      let code = sessionStorage.getItem("verifyArray") || ''
      if (that.is_play || code.indexOf(that.id) != -1) {
        let info = that.soninfo.data[index || 0];

        // 资源切换
        switch (sectiontype) {
          case 1:
            if (info.savetype == 2 || info.savetype == 4) {
              that.videoContent = info.videourl;
              that.videoVisible = true;
            } else {
              that.sid = info.id;
              that.is_free = info.is_free;
              that.testTime = info.testTime;
              that.playerOptions.sources[0].src = info.videourl;
              that.videoVisible = false;
            }
            break;
          case 2:
            that.content = info.content;
            that.contentVisible = true;
            break;
          case 3:
            that.$set(that.videoUpload.music, "title", info.title);
            that.$set(that.videoUpload.music, "pic", info.pic);
            that.$set(that.videoUpload.music, "url", info.videourl);
            break;
          case 4:
            window.open(info.videourl, "_self");
            break;
        }

        // 强制刷新组件
        that.reFresh = false;
        that.$nextTick(() => {
          that.reFresh = true;
        });
      } else {
        if(that.info.is_pass == 1) {
          MessageBox({
            message: '请选择观看课程方式',
            showConfirmButton: true,
            showCancelButton: true,	
            confirmButtonText: '购买课程',
            cancelButtonText: '密码验证'
          }).then(action => {
            if(action === 'confirm') {
              that.$router.push(`/lesson/onlineApply/${that.id}`);
            } else {
              that.passwordEvent()
            }
          });
        } else {
          that.$dialog.error("请购买课程！")
        }
      }
    },

    // 获取列表数据
    getList() {
      let that = this;

      lessonDetail(this.id)
        .then((res) => {
          let data = res.data;

          that.$set(that, "is_play", data.is_play);
          that.$set(that, "info", data.info);
          that.$set(that, "is_collect", data.is_collect);
          that.$set(that, "soninfo", data.soninfo);
          that.$set(that, "pay_qrcode", data.info.pay_qrcode);
          that.$set(that, "vipshare", data.info.vipshare);
          that.$set(that, "share", JSON.parse(data.info.share));
          that.initLoading = true;

          // 默认加载第一条数据
          if (data.soninfo.data.length > 0) {
            let info = data.soninfo.data[0];
            console.log(data.soninfo.data)
            that.firstParams = [
              info.sid,
              info.is_free,
              info.test_time,
              info.sectiontype,
              0,
            ];
            that.sectiontype = info.sectiontype;
            switch (that.sectiontype) {
              case 1:
                if (info.savetype == 2 || info.savetype == 4) {
                  that.videoContent = info.videourl;
                  that.videoVisible = true;
                } else {
                  that.sid = info.id;
                  that.is_free = info.is_free;
                  that.testTime = info.testTime;
                  that.playtime = info.playtime;
                  that.playerOptions.poster = data.info.images;
                  that.playerOptions.sources[0].src = info.videourl;
                  that.videoVisible = false;
                }
                break;
              case 2:
                break;
              case 3:
                that.$set(that.videoUpload.music, "title", info.title);
                that.$set(that.videoUpload.music, "pic", info.pic);
                that.$set(that.videoUpload.music, "url", info.videourl);
                break;
              case 4:
                // window.open(info.videourl, "_self");
                break;
            }
          }
          that.isView = true;
          that.setOpenShare();
          that.$dialog.loading.close();
        })
        .catch((err) => {
          that.isView = true;
          that.$dialog.error(err.msg);
          that.$dialog.loading.close();
        });
    },

    // 分享
    setOpenShare: function () {
      var data = this.info;
      var href = location.href;
      var share = this.share;

      if (_isWechat) {
        if (this.isLogin) {
          getUserInfo().then((res) => {
            href =
              href.indexOf("?") === -1
                ? href + "?spread=" + res.data.uid
                : href + "&spread=" + res.data.uid;
            this.spread = res.data.uid;
            var configAppMessage = {
              desc: share.descript
                ? share.descript
                : data.intro || "聚亿美线上教育平台",
              title: share.title ? share.title : data.bookname,
              link: href,
              imgUrl: share.images ? share.images : data.images,
            };
            wechatEvevt(
              [
                "updateAppMessageShareData",
                "updateTimelineShareData",
                "onMenuShareAppMessage",
                "onMenuShareTimeline",
              ],
              configAppMessage
            )
              .then((res) => {
                console.log(res);
              })
              .catch((res) => {
                if (res.is_ready) {
                  res.wx.updateAppMessageShareData(configAppMessage);
                  res.wx.onMenuShareAppMessage(configAppMessage);
                  res.wx.updateTimelineShareData(configAppMessage);
                  res.wx.onMenuShareTimeline(configAppMessage);
                }
              });
          });
        } else {
          var configAppMessage = {
            desc: share.descript
              ? share.descript
              : data.intro || "聚亿美线上教育平台",
            title: share.title ? share.title : data.bookname,
            link: href,
            imgUrl: share.images ? share.images : data.images,
          };
          wechatEvevt(
            [
              "updateAppMessageShareData",
              "updateTimelineShareData",
              "onMenuShareAppMessage",
              "onMenuShareTimeline",
            ],
            configAppMessage
          )
            .then((res) => {
              console.log(res);
            })
            .catch((res) => {
              if (res.is_ready) {
                res.wx.updateAppMessageShareData(configAppMessage);
                res.wx.onMenuShareAppMessage(configAppMessage);
                res.wx.updateTimelineShareData(configAppMessage);
                res.wx.onMenuShareTimeline(configAppMessage);
              }
            });
        }
      }
    },

    collect() {
      let that = this,
        id = that.id;

      if (that.is_collect) {
        getCollectDel(id, "lesson").then(() => {
          that.$set(that, "is_collect", false);
        });
      } else {
        getCollectAdd(id, "lesson").then(() => {
          that.$set(that, "is_collect", true);
        });
      }
    },

    // 支付
    pay() {
      let that = this
      if (that.is_play) {
        that.videoPlay(...that.firstParams);
      } else {
        that.$router.push(`/lesson/onlineApply/${that.id}`);
      }
    },

    // 密码验证
    passwordEvent() {
      let that = this;
      that.verifyCode = "";
      that.codeVisible = true;
    },

    // 客服
    service() {
      this.serviceVisible = true;
    },

    // 分享
    setShareInfoStatus: function () {
      this.shareInfoStatus = !this.shareInfoStatus;
    },

    // 海报
    poster() {
      let that = this;

      if (that.vipshare === 1) {
        let params = {
          banner: that.info.images,
          qrcode: window.location.href + "?spread=" + that.spread, // 当前页面地址+登录用户id
          lesson_id: that.id,
        };

        lessonBill(params)
          .then((res) => {
						
            that.$set(that, "posterImg", res.data);
            that.posterVisible = true;
          })
          .catch((err) => {
            that.$dialog.error(err.msg);
          });
      } else {
        that.setShareInfoStatus();
      }
    },

    // 验证密码
    submitCode() {
      let that = this;
      if (that.info.password == that.verifyCode) {
        that.$dialog.success("验证成功");
        that.$set(that, "codeVisible", false);
        that.$set(that, "is_play", true);

        let code = sessionStorage.getItem("verifyArray") || '';
        if (code.indexOf(that.id) == -1) {
          sessionStorage.setItem("verifyArray", code ? [that.id+','+code] : that.id);
        }
      } else {
        that.$dialog.error("密码不正确！");
      }
    },
  },
  destroyed() {
    // 如果定时器在运行则关闭
    if (this.timer) {
      clearInterval(this.timer);
    }
  },
};
</script>

<style lang="scss" scoped>
.online {
  padding-bottom: 1rem;
  .container {
    background: white;
    position: relative;
    .fixed-top {
      height: 4.4rem;
      width: 100%;
      overflow: hidden;
      position: fixed;
      top: 0;
      left: 0;
      z-index: 999;
    }
    img {
      width: 100%;
      height: auto;
    }
    .title {
      padding: 0.1rem 0.3rem;
      display: flex;
      justify-content: space-between;
      h4 {
        font-size: 0.28rem;
      }
      h6 {
        font-size: 0.26rem;
        font-weight: 500;
        line-height: 0.46rem;
        color: #b0b0b0;
      }
      .poster {
        i {
          font-size: 0.36rem;
        }
        text-align: center;
        font-size: 0.2rem;
      }
    }
    .text {
      padding: 0.1rem 0.3rem;
      display: flex;
      justify-content: space-between;
      i {
        font-size: 0.28rem;
      }
    }
    .switch {
      .list {
        height: 0.88rem;
        line-height: 0.88rem;
        border-top: solid #eaeaea 0.02rem;
        font-size: 0.26rem;
        padding: 0 0.3rem;
        display: flex;
        justify-content: space-between;
        .left {
          max-width: 5.5rem;
          display: flex;
          justify-content: space-between;
          i {
            font-size: 0.42rem;
            margin-right: 0.1rem;
          }
          span {
            max-width: 4.5rem;
          }
          em {
            font-size: 0.18rem;
            border: 0.02rem solid red;
            border-radius: 1rem;
            color: red;
            width: 0.6rem;
            height: 0.32rem;
            line-height: 0.32rem;
            text-align: center;
            margin: 0.28rem 0 0 0.1rem;
          }
        }
        .right {
          color: #a9a9a9;
          font-size: 0.24rem;
        }
      }
      .desc {
        line-height: 0.44rem;
        font-size: 0.26rem;
        padding: 0.2rem 0.3rem;
        border-top: solid #eaeaea 0.02rem;
        p {
          text-indent: 2em;
        }
      }
    }
  }
  .mint-popup {
    width: 5.5rem;
    background: transparent;
  }
  .navBarWrap {
    position: fixed;
    top: 4.4rem;
    z-index: 999;
    width: 100%;
    height: 0.95rem;
  }
  .music-fixed {
    position: fixed;
    top: 0;
    z-index: 999;
    width: 100%;
    margin: 0;
  }
  .verify {
    background: white;
    padding: 0.3rem 0 0.5rem;
    border-radius: 0.1rem;
    .code {
      width: 90%;
      margin: 0 auto;
      position: relative;
      padding-top: 0.2rem;
      input {
        width: 100%;
        height: 0.8rem;
        line-height: 0.8rem;
        border-bottom: 1px solid #ededed;
        font-size: 0.3rem;
        padding: 0 1.5rem 0 1rem;
      }
      i {
        font-size: 0.36rem;
        position: absolute;
        top: 0.38rem;
        left: 0.2rem;
        color: #f11b09;
      }
      button {
        position: absolute;
        top: 0.34rem;
        right: 0;
        height: 0.46rem;
        line-height: 0.46rem;
        width: 1.5rem;
        font-size: 0.26rem;
        border-radius: 0.3rem;
        color: white;
        text-align: center;
        background: #f35446;
      }
    }
    .submit {
      width: 90%;
      margin: 0 5%;
      font-size: 0.3rem;
      color: #fff;
      font-weight: 700;
      height: 0.8rem;
      border-radius: 0.43rem;
      background: linear-gradient(to right, #f35447 0, #ff8e3c 100%);
      text-align: center;
      line-height: 0.8rem;
      margin-top: 0.4rem;
    }
  }
}
</style>
